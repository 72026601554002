.accordion {
    background: #FFFFFF;
    padding: 39px 0 20px;
    h3 {
        margin-top: 0;
    }
    @include mobile {
        max-width: 100%;
    }
    @include small-mobile {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
    .container {
        padding: 0;
        @include touch {
            max-width: 100%;
        }
        @include xs-mobile {
            max-width: 100%;
        }
        .left {
            text-align: left;
        }
        .center {
            text-align: center;
        }
        .aPanel {
            margin-bottom: 20px;
            @include large-desktop {
                margin-bottom: 40px;
            }
            .accPanel {
                align-items: center;
                margin: auto;
                &[aria-expanded="true"]{
                    h4 {
                        color:$orange;
                    }
                    svg {
                        color:$orange;
                    }
                }
                .accSummaryImg {
                    margin-right: 40px;
                    img {
                        padding: 0;
                    }
                }
                &:first-child div { 
                    align-items: center;
                }
                h3 {
                    color: $teal;
                }
                img {
                    margin: auto;
                }
                svg {
                    color: $teal;
                    font-size: 2.5rem;
                }
            }
            .accContent {
                text-align: left;
                img {
                    padding: 20px;
                }
                li,h1,h2,h3,h4,h5,h6 {
                    color: inherit;
                }
            }
        }
    }
    .faq-page{
        background: #FFF;
        border-radius: 5px;
        border: 1px solid #e2e2e2;
        .faq-section {
            display: flex;
            .faq-img {
                padding-right: 20px;
                @include small-mobile{
                    padding-right: 15px;
                }
                img{
                    border-radius: 100%;
                    max-width: 100px;
                    width: 100px;
                    height: 100px;
                    object-fit: cover;
                    @include small-mobile{
                        max-width: 75px;
                        width: 75px;
                        height: 75px;
                    }
                }
            }
            .faq-content{
                h4{
                    margin: 0;
                    color: #007A8A;
                    text-align: left;
                    padding-bottom: 15px;
                    line-height: normal;
                }
                p{
                    margin: 0;
                    text-align: left;
                    color: #000;
                }
            }
        }
        & .accPanel[aria-expanded="true"] h4{
            color: #007A8A !important;
        }
        .accPanel{
            padding: 8px 20px;
            background: #FFF;
            border-radius: 5px;
        }
        & .accPanel[aria-expanded="true"]{
            border-radius: 5px 5px 0 0;
        }
        .accContent p{
            // margin: 0;
            color: #000;
        }
    }
}