.newEquityStatements-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #00464f;
  }
  .newEquityStatements-content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1100px;
    height: auto;
    margin: 60px 0 50px 0;
    padding: 0;
  }
  .newEquityStatements-content-container > h2 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 26px;
    color: #f1f1f1;
    letter-spacing: 1px;
    margin: 0;
  }
.newEquityStatements-info-container {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: row;
    width: 100%;
    max-width: 1100px;
    margin: 0;
    padding: 0;
}
.newEquityStatements-info-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    text-align: left;
    margin: 50px;
    padding: 0; 
}
.newEquityStatements-info-text > h3 {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: 20px;
    color: #f1f1f1;
    letter-spacing: 1px;
    margin: 0;
}
.newEquityStatements-info-text > p {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    color: #f1f1f1;
    font-size: 19px;
    text-align: left;
    line-height: 35px;
    margin-top: 0px;
    margin-bottom: 40px;
}
.newEquityStatements-info-image {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  text-align: left;
  margin: 50px;
  padding: 0;
}
.newEquityStatements-info-image > img {
  width: 100%;
}

.newEquityStatements-link {
    font-family: Helvetica Neue Medium;
    font-size: 16px;
    color: #f1f1f1;
    text-decoration: none;
  }

  .newEquityStatements-link:hover {
    color: #d14e1d;
  }


  @media only screen and (max-width: 1094px) {
    .newEquityStatements-info-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 1100px;
        margin: 0;
        padding: 0;
    }
    .newEquityStatements-info-text {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 400px;
        text-align: left;
        margin: 20px 0 20px 0;
        padding: 0; 
    }
    .newEquityStatements-info-text > h3 {
        font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
        font-size: 20px;
        color: #f1f1f1;
        letter-spacing: 1px;
        margin-bottom: 20px;
    }
    .newEquityStatements-info-text > p {
        font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
        color: #f1f1f1;
        font-size: 19px;
        text-align: left;
        line-height: 30px;
        margin-top: 0px;
        margin-bottom: 40px;
    }
    .newEquityStatements-info-image {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
      text-align: left;
      margin: 30px 0 20px 0;
      padding: 0;
    }
    .newEquityStatements-info-image > img {
      width: 100%;
    }
    
    .newEquityStatements-link {
        font-family: Helvetica Neue Medium;
        font-size: 16px;
        color: #f1f1f1;
        text-decoration: none;
      }
    
      .newEquityStatements-link:hover {
        color: #d14e1d;
      }
  }