#homeRedesign2023-Research-container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding-top: 50px;
    background-color: #00464f;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
}
.homeRedesign2023-Research-content-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    width: 100%;
    max-width: 1170px;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 30px;
}
.homeRedesign2023-Research-latest-news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
}
.homeRedesign2023-Research-latest-news::after {
  content: "";
  background: #007a8a;
  position: absolute;
  bottom: 0;
  left: 39%;
  height: 95%;
  width: 5px;
}
.homeRedesign2023-Research-latest-news > p {
    font-size: xx-large;
    text-align: left;
}
.homeRedesign2023-Research-latest-news-card {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 80%;
    background-color: white;
    box-shadow: 5px 5px lightgray;
    margin-bottom: 30px;
}
.homeRedesign2023-Research-latest-news-card > a {
    text-decoration: none;
}
.homeRedesign2023-Research-latest-news-card img {
    width: 100%;
}
.homeRedesign2023-Research-latest-news-card-text {
    color: black;
    text-align: left;
    padding: 0 15px;
}
.homeRedesign2023-Research-latest-news-card-title {
    font-size: x-large;
}
.homeRedesign2023-Research-latest-news-card-blurb {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    font-size: medium;
}
.homeRedesign2023-Research-latest-news-card-date-arrow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.homeRedesign2023-Research-latest-news-card-date {
    font-size: medium;
    color: #575857;
}
.homeRedesign2023-Research-latest-news-card-arrow {
    font-size: 50px;
    color: #007a8a;
    line-height: 0;
}
.homeRedesign2023-Research-labs {
    box-sizing: border-box;
      width: 60%;
      padding-left: 5%;
}
.homeRedesign2023-Research-labs > p {
    font-size: xx-large;
    text-align: left;
}
.homeRedesign2023-Research-labs-tiles {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      grid-template-rows: 1fr 1fr;
      row-gap: 20px;
}
.homeRedesign2023-Research-labs-eachTile {
      height: 280px;
      text-decoration: none;
}
.homeRedesign2023-Research-labs-eachTile span {
      padding: 0 20px 20px 20px;
      text-align: left;
}
  .homeRedesign2023-Research-labs-tileOne,
  .homeRedesign2023-Research-labs-tileTwo,
  .homeRedesign2023-Research-labs-tileThree,
  .homeRedesign2023-Research-labs-tileFour {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    font-size: x-large;
    height: 280px;
    text-align: left;
    padding: 0 20px;
}
.homeRedesign2023-Research-labs-tileOne h3,
.homeRedesign2023-Research-labs-tileTwo h3,
.homeRedesign2023-Research-labs-tileThree h3,
.homeRedesign2023-Research-labs-tileFour h3 {
    font-size: x-large;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}

.homeRedesign2023-Research-labs-tileOne {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(0, 122, 138, 0.7)), url('https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Research+Landing+Page/2022+Redesign/Labs/Conceptual+Development+Lab+Research+Landing+Page+Card.jpeg');
}
.homeRedesign2023-Research-labs-tileTwo {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(0, 122, 138, 0.7)), url('https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Home+Page/2023+Redesign/400x280_Digital-Tech-Lab_Thumbnails.jpg');
}
.homeRedesign2023-Research-labs-tileThree {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(0, 122, 138, 0.7)), url('https://s3.us-west-2.amazonaws.com/craft-images/STEM-DBR-Lab/STEM-DBR-Second-Replacement-Hero-Image.jpg');
}
.homeRedesign2023-Research-labs-tileFour {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), rgba(0, 122, 138, 0.7)), url('https://f10-research.s3.us-west-2.amazonaws.com/Youth+and+Educator+SEL+Lab/Youth+and+Educator+SEL+Lab+Card.jpg');
}
.homeRedesign2023-Research-labs-tiles p {
    display: inline;
    position: relative;
    color: white;
    font-size: x-large;
    padding: 0 15px;
    bottom: 30%;
}

  @media only screen and (max-width: 1350px) {
    .homeRedesign2023-Research-content-container {
      max-width: 1024px;
      margin-top: 30px;
    }
  }
  
  @media only screen and (max-width: 1150px) {
    #homeRedesign2023-Research-container{
      padding-top: 0;
      background-color: #F0F0F1;
      color: black;
      }
    .homeRedesign2023-Research-content-container {
      max-width: 512px;
      max-height: 10000px;
      margin-top: 0px;
      margin-bottom: 0px;
      flex-direction: column;
      padding-bottom: 30px;
    }   
    .homeRedesign2023-Research-latest-news {
      width: 100%;
    }    
    .homeRedesign2023-Research-latest-news::after {
      display: none;
      }   
      .homeRedesign2023-Research-latest-news > p {
      text-align: center;
    }
    .homeRedesign2023-Research-latest-news-card {
      width: 100%;
    }
    .homeRedesign2023-Research-latest-news-card-title {
      margin-bottom: 0;
    }    
    .homeRedesign2023-Research-latest-news-card-blurb {
      display: none;
    }
    .homeRedesign2023-Research-latest-news-card-date {
      display: none;
    }
    .homeRedesign2023-Research-latest-news-card-arrow {
      font-size: 60px;
    }
    .homeRedesign2023-Research-labs {
      box-sizing: border-box;
      width: 100%;
      padding-left: 0;
    }
    .homeRedesign2023-Research-labs > p {
      text-align: center;
    }
    .homeRedesign2023-Research-labs-tiles {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 0;
      grid-template-rows: 1fr 1fr 1fr 1fr;
  }
    .homeRedesign2023-Research-labs-eachTile {
      height: 180px;
    }
    .homeRedesign2023-Research-labs-eachTile span {
      padding: 20px;
      text-align: center;
  }  
  .homeRedesign2023-Research-labs-tileOne,
  .homeRedesign2023-Research-labs-tileTwo,
  .homeRedesign2023-Research-labs-tileThree,
  .homeRedesign2023-Research-labs-tileFour {
      justify-content: center;
      background-position: initial;
      background-position-y: 33%;
      height: 180px;
  }
  .homeRedesign2023-Research-labs-tileOne {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('https://f10-image.s3.us-west-2.amazonaws.com/New+Site/Research+Landing+Page/2022+Redesign/Labs/Conceptual+Development+Lab+Research+Landing+Page+Card.jpeg');
  }
  .homeRedesign2023-Research-labs-tileTwo {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('https://f10-research.s3.us-west-2.amazonaws.com/Digital+Technologies+%26+Education+Lab/Digital+Technologies+Lab+card.jpeg');
  }
  .homeRedesign2023-Research-labs-tileThree {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('https://s3.us-west-2.amazonaws.com/craft-images/STEM-DBR-Lab/STEM-DBR-Second-Replacement-Hero-Image.jpg');
  }
  .homeRedesign2023-Research-labs-tileFour {
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url('https://f10-research.s3.us-west-2.amazonaws.com/Youth+and+Educator+SEL+Lab/Youth+and+Educator+SEL+Lab+Card.jpg');
  }
}    
  
  @media only screen and (max-width: 900px) {
    .homeRedesign2023-Research-content-container {
      margin-top: 30px;
    }    
  }
  
  @media only screen and (max-width: 700px) {
    .homeRedesign2023-Research-content-container {
      max-width: 400px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  
  @media only screen and (max-width: 550px) {
    .homeRedesign2023-Research-content-container {
      max-width: 375px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .homeRedesign2023-Research-content-container {
      max-width: 300px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  
  @media only screen and (max-width: 380px) {
    .homeRedesign2023-Research-content-container {
      max-width: 280px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .homeRedesign2023-Research-content-container {
      max-width: 260px;
    }
  }