#careers-attributes-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.careers-attributeOne-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 1280px;
  top: 0;
  left: 0;
  margin-top: 80px;
  margin-bottom: 50px;
}
.attributeOne-content-image {
  display: flex;
  width: 600px;
  height: 450px;
  margin-right: 40px;
}
.attributeOne-content-image > img {
  width: 600px;
  height: 450px;
}
.attributeOne-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 600px;
  height: 450px;
  margin-left: 40px;
}
.attributeOne-content-title {
    display: flex;
    align-items: flex-start;
    width: 100%;
    width: 320px;
    height: 100%;
    max-height: 110px;
    text-align: left;
    margin-left: 50px;
    margin-bottom: 15px;
    border-bottom: 3px solid #000;
  }
.attributeOne-content-title > h3 {
  color: #000;
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: lighter;
  line-height: 65px;
  letter-spacing: 1px;
}
.attributeOne-content-text > p {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: #000;
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
  line-height: 25px;
}


.careers-attributeTwo-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 1280px;
  top: 0;
  left: 0;
  margin-bottom: 50px;
}
.attributeTwo-content-image {
  display: flex;
  width: 600px;
  height: 450px;
  margin-left: 40px;
}
.attributeTwo-content-image > img {
  width: 600px;
  height: 450px;
}
.attributeTwo-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 600px;
  height: 450px;
  margin-right: 40px;
}
.attributeTwo-content-title {
    display: flex;
    align-items: flex-start;
    width: 100%;
    width: 320px;
    height: 100%;
    max-height: 110px;
    text-align: left;
    margin-left: 50px;
    margin-bottom: 15px;
    border-bottom: 3px solid #000;
  }
.attributeTwo-content-title > h3 {
  color: #000;
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: lighter;
  line-height: 65px;
  letter-spacing: 1px;
}
.attributeTwo-content-text > p {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: #000;
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
  line-height: 25px;
}

.careers-attributeThree-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 1280px;
  top: 0;
  left: 0;
  margin-bottom: 50px;
}
.attributeThree-content-image {
  display: flex;
  width: 600px;
  height: 450px;
  margin-right: 40px;
}
.attributeThree-content-image > img {
  width: 600px;
  height: 450px;
}
.attributeThree-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 600px;
  height: 450px;
  margin-left: 40px;
}
.attributeThree-content-title {
    display: flex;
    align-items: flex-start;
    width: 100%;
    width: 320px;
    height: 100%;
    max-height: 110px;
    text-align: left;
    margin-left: 50px;
    margin-bottom: 15px;
    border-bottom: 3px solid #000;
  }
.attributeThree-content-title > h3 {
  color: #000;
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: lighter;
  line-height: 65px;
  letter-spacing: 1px;
}
.attributeThree-content-text > p {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: #000;
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
  line-height: 25px;
}

.careers-attributeFour-content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  height: 100%;
  max-height: 1280px;
  top: 0;
  left: 0;
  margin-bottom: 80px;
}
.attributeFour-content-image {
  display: flex;
  width: 600px;
  height: 450px;
  margin-left: 40px;
}
.attributeFour-content-image > img {
  width: 600px;
  height: 450px;
}
.attributeFour-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 600px;
  height: 450px;
  margin-right: 40px;
}
.attributeFour-content-title {
    display: flex;
    align-items: flex-start;
    width: 100%;
    width: 320px;
    height: 100%;
    max-height: 110px;
    text-align: left;
    margin-left: 50px;
    margin-bottom: 15px;
    border-bottom: 3px solid #000;
  }
.attributeFour-content-title > h3 {
  color: #000;
  font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: lighter;
  line-height: 65px;
  letter-spacing: 1px;
}
.attributeFour-content-text > p {
  font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
  font-size: 17px;
  color: #000;
  text-align: left;
  margin-left: 50px;
  margin-right: 50px;
  line-height: 25px;
}

@media only screen and (max-width: 1350px) {
  .attributeOne-content-image {
    width: 500px;
    height: 375px;
  }
  .attributeOne-content-image > img {
    width: 500px;
    height: 375px;
  }
  .attributeOne-content-text {
    width: 500px;
    height: 375px;
    margin-left: 20px;
  } 
  
  .attributeTwo-content-image {
    width: 500px;
    height: 375px;
  }
  .attributeTwo-content-image > img {
    width: 500px;
    height: 375px;
  }
  .attributeTwo-content-text {
    width: 500px;
    height: 375px;
    margin-right: 20px;
  }
  .attributeThree-content-image {
    width: 500px;
    height: 375px;
  }
  .attributeThree-content-image > img {
    width: 500px;
    height: 375px;
  }
  .attributeThree-content-text {
    width: 500px;
    height: 375px;
    margin-left: 20px;
  }

  .attributeFour-content-image {
    width: 500px;
    height: 375px;
  }
  .attributeFour-content-image > img {
    width: 500px;
    height: 375px;
  }
  .attributeFour-content-text {
    width: 500px;
    height: 375px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 1150px) {
  .attributeOne-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeOne-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeOne-content-text {
    width: 450px;
    height: 337px;
  }
  .attributeOne-content-title {
      width: 280px;
      max-height: 90px;
      margin-bottom: 0px;
    }
  .attributeOne-content-title > h3 {
    font-size: 35px;
  }
  .attributeOne-content-text > p {
    font-size: 15px;
  }
  
    .attributeTwo-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeTwo-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeTwo-content-text {
    width: 450px;
    height: 337px;
  }
  .attributeTwo-content-title {
      width: 280px;
      max-height: 90px;
      margin-bottom: 0px;
    }
  .attributeTwo-content-title > h3 {
    font-size: 35px;
  }
  .attributeTwo-content-text > p {
    font-size: 15px;
  }
  
  .attributeThree-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeThree-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeThree-content-text {
    width: 450px;
    height: 337px;
  }
  .attributeThree-content-title {
      width: 280px;
      max-height: 90px;
      margin-bottom: 0px;
    }
  .attributeThree-content-title > h3 {
    font-size: 35px;
  }
  .attributeThree-content-text > p {
    font-size: 15px;
  }
  
  .attributeFour-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeFour-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeFour-content-text {
    width: 450px;
    height: 337px;
  }
  .attributeFour-content-title {
      width: 280px;
      max-height: 90px;
      margin-bottom: 0px;
    }
  .attributeFour-content-title > h3 {
    font-size: 35px;
  }
  .attributeFour-content-text > p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .careers-attributeOne-content-container {
    flex-direction: column;
    max-height: 2560px;
    margin-top: 50px;
  }
  .attributeOne-content-image {
    width: 700px;
    height: 525px;
    margin-right: 0px;
  }
  .attributeOne-content-image > img {
    width: 700px;
    height: 525px;
  }
  .attributeOne-content-text {
    width: 700px;
    height: 250px;
    margin-left: 0px;
  }
  .attributeOne-content-title {
      width: 200px;
    }
  .attributeOne-content-text > p {
    margin-bottom: 40px;
  }
  
  
  .careers-attributeTwo-content-container {
    flex-direction: column-reverse;
  }
  .attributeTwo-content-image {
    width: 700px;
    height: 525px;
    margin-left: 0px;
  }
  .attributeTwo-content-image > img {
    width: 700px;
    height: 525px;
  }
  .attributeTwo-content-text {
    width: 700px;
    height: 250px;
    margin-right: 0px;
  }
  .attributeTwo-content-title {
      width: 200px;
    }
  .attributeTwo-content-text > p {
    margin-bottom: 40px;
  }
  
  .careers-attributeThree-content-container {
    flex-direction: column;
  }
  .attributeThree-content-image {
    width: 700px;
    height: 525px;
    margin-right: 0px;
  }
  .attributeThree-content-image > img {
    width: 700px;
    height: 525px;
  }
  .attributeThree-content-text {
    width: 700px;
    height: 250px;
    margin-left: 0px;
  }
  .attributeThree-content-title {
      width: 250px;
    }
  .attributeThree-content-text > p {
    margin-bottom: 40px;
  }
  
  .careers-attributeFour-content-container {
    flex-direction: column-reverse;
  }
  .attributeFour-content-image {
    width: 700px;
    height: 525px;
    margin-left: 0px;
  }
  .attributeFour-content-image > img {
    width: 700px;
    height: 525px;
  }
  .attributeFour-content-text {
    width: 700px;
    height: 250px;
    margin-right: 0px;
  }
  .attributeFour-content-title {
      width: 250px;
    }
  .attributeFour-content-text > p {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .attributeOne-content-image {
    width: 600px;
    height: 450px;
  }
  .attributeOne-content-image > img {
    width: 600px;
    height: 450px;
  }
  .attributeOne-content-text {
    width: 600px;
    height: 270px;
  }  
  
  .attributeTwo-content-image {
    width: 600px;
    height: 450px;
  }
  .attributeTwo-content-image > img {
    width: 600px;
    height: 450px;
  }
  .attributeTwo-content-text {
    width: 600px;
    height: 270px;
  }
  .attributeThree-content-image {
    width: 600px;
    height: 450px;
  }
  .attributeThree-content-image > img {
    width: 600px;
    height: 450px;
  }
  .attributeThree-content-text {
    width: 600px;
    height: 270px;
  }

  .attributeFour-content-image {
    width: 600px;
    height: 450px;
  }
  .attributeFour-content-image > img {
    width: 600px;
    height: 450px;
  }
  .attributeFour-content-text {
    width: 600px;
    height: 270px;
  }
}

@media only screen and (max-width: 600px) {
  .attributeOne-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeOne-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeOne-content-text {
    width: 450px;
    height: 320px;
  }
  
  .attributeTwo-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeTwo-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeTwo-content-text {
    width: 450px;
    height: 320px;
  }
  .attributeThree-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeThree-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeThree-content-text {
    width: 450px;
    height: 320px;
  }

  .attributeFour-content-image {
    width: 450px;
    height: 337px;
  }
  .attributeFour-content-image > img {
    width: 450px;
    height: 337px;
  }
  .attributeFour-content-text {
    width: 450px;
    height: 320px;
  }
}

@media only screen and (max-width: 500px) {
  .attributeOne-content-image {
    width: 350px;
    height: 262px;
  }
  .attributeOne-content-image > img {
    width: 350px;
    height: 262px;
  }
  .attributeOne-content-text {
    width: 350px;
    height: 300px;
  }
  .attributeOne-content-title {
      width: 150px;
    }
  .attributeOne-content-title > h3 {
    font-size: 25px;
    line-height: 95px;   
  }
  .attributeOne-content-text > p {
    margin-bottom: 60px;
    line-height: 20px;
  }
  
  
  .attributeTwo-content-image {
    width: 350px;
    height: 262px;
  }
  .attributeTwo-content-image > img {
    width: 350px;
    height: 262px;
  }
  .attributeTwo-content-text {
    width: 350px;
    height: 300px;
  }
  .attributeTwo-content-title {
      width: 150px;
    }
  .attributeTwo-content-title > h3 {
    font-size: 25px;
    line-height: 95px;
  }
  .attributeTwo-content-text > p {
    margin-bottom: 60px;
    line-height: 20px;
  }
  .attributeThree-content-image {
    width: 350px;
    height: 262px;
  }
  .attributeThree-content-image > img {
    width: 350px;
    height: 262px;
  }
  .attributeThree-content-text {
    width: 350px;
    height: 300px;
  }
  .attributeThree-content-title {
      width: 200px;
    }
  .attributeThree-content-title > h3 {
    font-size: 25px;
  }
  .attributeThree-content-text > p {
    margin-bottom: 60px;
    line-height: 20px;
  }
  
  .attributeFour-content-image {
    width: 350px;
    height: 262px;
  }
  .attributeFour-content-image > img {
    width: 350px;
    height: 262px;
  }
  .attributeFour-content-text {
    width: 350px;
    height: 330px;
  }
  .attributeFour-content-title {
      width: 200px;
    }
  .attributeFour-content-title > h3 {
    font-size: 25px;
    line-height: 95px;
  }
  .attributeFour-content-text > p {
    margin-bottom: 60px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .attributeOne-content-image {
    width: 320px;
    height: 240px;
  }
  .attributeOne-content-image > img {
    width: 320px;
    height: 240px;
  }
  .attributeOne-content-text {
    width: 320px;
  }
  .attributeOne-content-title {
      margin-left: 30px;
    }
  .attributeOne-content-text > p {
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;
    line-height: 20px;
  }
  
  .attributeTwo-content-image {
    width: 320px;
    height: 240px;
  }
  .attributeTwo-content-image > img {
    width: 320px;
    height: 240px;
  }
  .attributeTwo-content-text {
    width: 320px;
    height: 280px;
  }
  .attributeTwo-content-title {
      margin-left: 30px;
    }
  .attributeTwo-content-text > p {
    margin-left: 30px;
    margin-right: 30px;
  }
  .attributeThree-content-image {
    width: 320px;
    height: 240px;
  }
  .attributeThree-content-image > img {
    width: 320px;
    height: 240px;
  }
  .attributeThree-content-text {
    width: 320px;
  }
  .attributeThree-content-title {
      width: 180px;
      margin-left: 30px;
    }
  .attributeThree-content-title > h3 {
    line-height: 95px;
  }
  .attributeThree-content-text > p {
    margin-left: 30px;
    margin-right: 30px;
  }
  
  .careers-attributeFour-content-container {
    margin-bottom: 50px;
  }
  .attributeFour-content-image {
    width: 320px;
    height: 240px;
  }
  .attributeFour-content-image > img {
    width: 320px;
    height: 240px;
  }
  .attributeFour-content-text {
    width: 320px;
    height: 320px;
  }
  .attributeFour-content-title {
      margin-left: 30px;
    }
  .attributeFour-content-text > p {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media only screen and (max-width: 350px) {
  .attributeOne-content-image {
    width: 280px;
    height: 210px;
  }
  .attributeOne-content-image > img {
    width: 280px;
    height: 210px;
  }
  .attributeOne-content-text {
    width: 280px;
  }
  .attributeOne-content-title {
      margin-left: 20px;
    }
  .attributeOne-content-text > p {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .attributeTwo-content-image {
    width: 280px;
    height: 210px;
  }
  .attributeTwo-content-image > img {
    width: 280px;
    height: 210px;
  }
  .attributeTwo-content-text {
    width: 280px;
    height: 260px;
  }
  .attributeTwo-content-title {
      margin-left: 20px;
    }
  .attributeTwo-content-text > p {
    margin-left: 20px;
    margin-right: 20px;
  }
  .attributeThree-content-image {
    width: 280px;
    height: 210px;
  }
  .attributeThree-content-image > img {
    width: 280px;
    height: 210px;
  }
  .attributeThree-content-text {
    width: 280px;
  }
  .attributeThree-content-title {
      margin-left: 20px;
    }
  .attributeThree-content-text > p {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .attributeFour-content-image {
    width: 280px;
    height: 210px;
  }
  .attributeFour-content-image > img {
    width: 280px;
    height: 210px;
  }
  .attributeFour-content-text {
    width: 280px;
  }
  .attributeFour-content-title {
      margin-left: 20px;
    }
  .attributeFour-content-text > p {
    margin-left: 20px;
    margin-right: 20px;
  }
}