#homeRedesign2023-Partnership-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F2F2F2;
    padding-bottom: 50px;
    ;
  }
  .homeRedesign2023-Partnership-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1170px;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 30px;
  }
  .homeRedesign2023-Partnership-top {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1170px;
      height: 100%;
      max-height: 250px;
      margin: 20px 0 0 0;
  }
  .homeRedesign2023-Partnership-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1170px;
    height: 100%;
}
  .homeRedesign2023-Partnership-top > p {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    color: #01646F;
    text-align: left;
    font-weight: bolder;
    font-size: 18px;
    line-height: 1;
  } 
  .homeRedesign2023-Partnership-bottom > p {
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    color: black;
    text-align: left;
    font-weight: bolder;
    font-size: 32px;
    line-height: 1;
    margin-top: 0;
  }
  .homeRedesign2023-Partnership-images {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 250px;
    column-gap: 20px;
    overflow: hidden;
  }
  .homeRedesign2023-Partnership-images-card > img {
    width: 300px;
    height: 250px;
  }
  .homeRedesign2023-Partnership-images-text {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
    font-size: x-large;
    color: white;
    position: absolute;
    bottom: 0;
    width: 300px;
    height: 75px;
    background-color: rgba(0, 122, 138, 0.7);
  }

  @media only screen and (max-width: 1350px) {
    .homeRedesign2023-Partnership-content-container {
      max-width: 1024px;
      margin-top: 30px;
    }
    .homeRedesign2023-Partnership-top {
        max-width: 1024px;
    }
  }
  
  @media only screen and (max-width: 1150px) {

    #homeRedesign2023-Partnership-container{
        background-color: #007A8A;
        padding-bottom: 0;
      }
    .homeRedesign2023-Partnership-content-container {
      max-width: 512px;
      max-height: 10000px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .homeRedesign2023-Partnership-top {
        max-width: 512px;
        max-height: 10000px;
    }
    .homeRedesign2023-Partnership-top > p {
        color: white;
    }
    
      .homeRedesign2023-Partnership-bottom > p {
        color: white;
    }

    .homeRedesign2023-Partnership-images {
      display: none;
    }   
  }
  
  @media only screen and (max-width: 900px) {
    .homeRedesign2023-Partnership-content-container {
      margin-top: 30px;
    }    
    .homeRedesign2023-Partnership-top > p {
      font-size: 17px;
    }
  }
  
  @media only screen and (max-width: 700px) {
    .homeRedesign2023-Partnership-content-container {
      max-width: 400px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .homeRedesign2023-Partnership-top {
      max-width: 400px;
    }
  }
  
  @media only screen and (max-width: 550px) {
    .homeRedesign2023-Partnership-content-container {
      max-width: 375px;
    }
    .homeRedesign2023-Partnership-top {
      max-width: 375px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .homeRedesign2023-Partnership-content-container {
      max-width: 300px;
    }
    .homeRedesign2023-Partnership-top {
        max-width: 300px;
    }
    .homeRedesign2023-Partnership-bottom {
      max-height: fit-content;
    }
    .homeRedesign2023-Partnership-bottom p {
      margin: 0;
      padding-bottom: 20px;
    }
  }
  
  @media only screen and (max-width: 380px) {
    .homeRedesign2023-Partnership-content-container {
      max-width: 280px;
    }
    .homeRedesign2023-Partnership-top {
      max-width: 280px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .homeRedesign2023-Partnership-content-container {
      max-width: 260px;
    }
    .homeRedesign2023-Partnership-top {
      max-width: 260px;
    }
  }