#homeRedesign2023-IAs-container{
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    padding-top: 50px;
    background-color: white;
    font-family: 'Helvetica Neue Medium', Arial, Helvetica, sans-serif;
}
.homeRedesign2023-IAs-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1170px;
    height: 100%;
    /* max-height: 345px; */
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 30px;
}
.homeRedesign2023-IAs-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}
.homeRedesign2023-IAs-header-container::after {
    content: "";
    background: black;
    position: absolute;
    top: 56px;
    left: 3%;
    height: 2px;
    width: 94%;
}
.homeRedesign2023-IAs-header-container :first-child{
    font-size: xx-large;
    z-index: 2;
    background-color: white;
    padding: 0 15px;
}
.homeRedesign2023-IAs-header-container :last-child{
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    max-width: 80%;
    line-height: 1.5;
}
.homeRedesign2023-IAs-teams-container {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    row-gap: 10px;
}
.homeRedesign2023-IAs-teams-container a {
    text-decoration: none;
}
.homeRedesign2023-IAs-eachTeam {
    display: flex;
    flex-direction: row;
    background-color: #F2F2F2;
    padding: 50px;
}
.homeRedesign2023-IAs-eachTeam img {
    margin-right: 50px;
    max-width: 300px;
}
.homeRedesign2023-IAs-team-card-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    text-align: left;
}
.homeRedesign2023-IAs-team-card-title {
    font-size: xx-large;
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
}
.homeRedesign2023-IAs-team-card-blurb {
    font-family: 'Helvetica Neue Light', Arial, Helvetica, sans-serif;
    line-height: 1.5;
    color: black;
    position: relative;
    bottom: 10%;
    text-align: left;
}
.homeRedesign2023-IAs-team-card-arrow {
    color: #F9F9F9;
    background-color: #BE5127;
    padding: 10px 20px;
    border-radius: 25px;
    font-size: medium;
}

@media only screen and (max-width: 1350px) {
    .homeRedesign2023-IAs-content-container {
      max-width: 1024px;
      margin-top: 30px;
    }
  }
  
  @media only screen and (max-width: 1150px) {

    #homeRedesign2023-IAs-container{
        padding-top: 0;
      }
    
    .homeRedesign2023-IAs-content-container {
      max-width: 512px;
      max-height: 10000px;
      margin-top: 0px;
      margin-bottom: 0px;
    }   

    .homeRedesign2023-IAs-header-container::after {
        display: none;
    }

    .homeRedesign2023-IAs-teams-container {
      display: flex;
      flex-direction: column;
  }  

    .homeRedesign2023-IAs-eachTeam {
        flex-direction: column;
        background-color: white;
        padding: 0;
        border: 2px #007A8A solid;
        margin-bottom: 30px;
    }
    .homeRedesign2023-IAs-eachTeam img {
        margin-right: 0;
        max-width: 100%;
    }    
    .homeRedesign2023-IAs-team-card-text {
        padding: 20px;
    }
    .homeRedesign2023-IAs-team-card-blurb {
        line-height: 2;
    }
  }

  @media only screen and (max-width: 900px) {
    .homeRedesign2023-IAs-content-container {
      margin-top: 30px;
    }   
  }
  
  @media only screen and (max-width: 700px) {
    .homeRedesign2023-IAs-content-container {
      max-width: 400px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  
  @media only screen and (max-width: 550px) {
    .homeRedesign2023-IAs-content-container {
      max-width: 375px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .homeRedesign2023-IAs-content-container {
      max-width: 300px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
  
  @media only screen and (max-width: 380px) {
    .homeRedesign2023-IAs-content-container {
      max-width: 280px;
    }
  }
  
  @media only screen and (max-width: 350px) {
    .homeRedesign2023-IAs-content-container {
      max-width: 260px;
    }
  }