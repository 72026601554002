.pageContainer {
    @include mobile {
        // padding-left: 32px;
        // padding-right: 32px;
        max-width: 100%;
    }
    @include small-mobile {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
    .pageContent {
        background: #FFF;
    }
    .container {
        padding: 60px 100px;
        @include touch {
            padding-left: 32px;
            padding-right: 32px;
            max-width: 100%;
        }
        @include xs-mobile {
            padding-left: 32px;
            padding-right: 32px;
            max-width: 100%;
        }
        h1 {
            color: #00464F;
            margin-top: 10px;
            text-align: left;
        }
        .catLabel {
            color: $orange-text;
            text-transform: uppercase;
            text-align: left;
        }
        .entryBlurb {
            text-align: left;
            p {
                color: $gray-text;  
                font-weight: 600;
                font-size: 25px;
                line-height: 125%;
                color: #434242;
            }
        }
        .featuredImage {
            // margin: 60px 0 20px 0;
            margin: 0 0 -4px 0;
        }
        .featuredImageCaption {
            color: $gray-text;
            // margin: 0 0 20px 0;
            margin: 0;
        }
        .catTags {
            padding: 30px 0;
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            a {
                border: $teal 2px solid;
                border-radius: 4px;
                display: inline-block;
                font-weight: 600;
                margin-right: 20px;
                padding: 10px 15px;
                text-decoration: none;
                margin-bottom: 10px;
                i {
                    margin-right: 5px;
                }
            }
            
        }
        .authorSection {
            border-top: #AEAEAE solid 1px;
            padding: 40px 0;
            display: flex;
            text-align: left;
            @include mobile-full{
                flex-wrap: wrap;
                padding-bottom: 60px;
            }
            .authorImage {
                width: 30%;
                @include mobile-full{
                    flex: 0 0 100%;
                }
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
            .authorContent {
                width: 60%;
                padding-left: 40px;
                @include mobile-full{
                    flex: 0 0 100%;
                    padding: 50px 0 0;
                }
                h5 {
                    color: $gray-text;
                    margin: 0;
                    text-transform: uppercase;
                    @include mobile-full{
                        font-size: 12px;
                        line-height: 15px;
                        font-weight: 600;
                    }
                }
                h4 {
                    font-size: 20px;
                    color: #434242;
                    @include mobile-full{
                        margin: 0 0 20px;
                    }
                }
                h3 {
                    color: $teal;
                    @include mobile-full{
                        margin: 20px 0 10px;
                    }
                }
                p{
                    color: #000;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    margin-top: 0;
                }
                .readMore {
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    padding-top:30px;
                    text-decoration: none;
                    text-transform: uppercase;
                    i {
                        padding-left: 5px
                    }
                }
            }
        }
    }
    .pages-block{
        @include tablet-full{
            padding-left: 32px;
            padding-right: 32px;
        }
    }
}
.relatedPosts {
    background-color: $bg-light;
    padding: 60px 32px;
    @include mobile {
        padding: 0 32px 26px !important;
        background-color: #fff;;
    }
    h2 {
        text-align: left;
        @include mobile-full{
            text-align: center;
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 60px;
            margin-top: 0;
        }
    }
}
.homepageContainer {
    background: #FFF;
}

.share-btn {
    display: inline-block;
    margin: 0 0 0 auto;
    position: relative;

    button {
        border: 1px solid #000;
        font-size: 20px;
        text-decoration: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 50px;
        background: #000000;
        color: #fff;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
        text-align: center;
        justify-content: center;
        transition: 0.3s;
        cursor: pointer;
        padding: 10px 15px;
        img {
            padding: 0 0 0 10px;
        }
        &:hover{
           color: #D14E1D; 
           background:#fff;
           border-color: #D14E1D;
        }
    }

    .share { position: absolute;
             right: -35px;
             width: 342px;
             top: 29px;
         a { margin: 0px;
        img { padding: 0px!important;}
        }

    }
}

.globalBreadcrumbs .share-btn .share {right: -30px; top: 45px; z-index: 99;}